.pt_checkout {
	&#wrapper {
		#main {
			padding-top: 92px !important;
		}
	}

	.checkout-tab {
		width: 100%;
		display: inline-block;
		.checkout-tab-head{
			h2,h1 {
				letter-spacing: .05px;
			}
		}
	}

	.error-tender-rebuttal {
		font-family: $Helvetica;
	}
	.basket-details{
		.cart-row.product-brylane-lineitem{
			.cart-columns .col-1{
				.item-image img{
						object-fit: cover;
					    min-height: 144px;
    				@media screen and (max-width: 360px) {
    					min-height: 196px;
    				}
    				@media screen and (min-width: 361px)  and (max-width: 767px) {
    					min-height: 204px;
    				}
				}
			}
		}
	}
	.cart-row {
		.col-1 {
			.item-details {
				.name {
					letter-spacing: 1px;
					font-weight: normal;
				}
				.sku {
					font-family: $Helvetica;
					color: black;
					margin: 2px 0 10px 0;
				}
				.attribute {
					margin-bottom: 7px;

					&[data-attribute="braCupSize"] {
						text-transform: uppercase;
					}
					span {
						letter-spacing: 1.5px;
					}

					.value, .label {
						font-weight: normal;
						text-transform: uppercase;
    					letter-spacing: 1px;
					}
					.label {
						font-weight: bold;
					}
				}
			}
		}
		.column{
			&.col-2{
				width: 25%;
    			text-align: right;
    			max-width: 100%;
    			.custom-cart-qty{
    				margin-top: 42%;
    			}
			}
			&.col-3{
				width: 21%;
				max-width: 100%;
				.item-total{
					margin-top: 49%;
				}
			}
		}
	}
	.form-row {
		label {
		    span {
		    	font-family: $HelveticaMedium;
			    color: $dim-gray;
			    text-transform: capitalize;
		    }
		}
	}
	.make-label-absolute {
		label {
		    span {
		    	&.error {
		    		font-size: $base-font;
		    		font-family: $HelveticaMedium;
		    	}
		    }
		    &.input-focus {
		    	span {
		    		&.error {
			    		font-size: $base-font - 1px;
			    	}
		    	}
		    }
		}
	}
	//Q&A
	.qa-section {
		.question-title {
			padding: 26px 25px 25px 39px;
		}

		.qa-content {
			padding: 23px 17px 20px 43px;
    		letter-spacing: 1px;

    		&:nth-child(2n), &:nth-child(3n), &:nth-child(4n),&:nth-child(n) {
    			padding: 23px 17px 20px 43px;
    			letter-spacing: 1px;
    		}

    		.question {
    			&::after {
	    			background: url(../../../images/arrow-down.png) no-repeat;
					background-position: 99.7% 4px;
				}

				&.active {
					&::after {
						background: url(../../../images/arrow-up.png) no-repeat;
	    				background-position: 99.7% 4px;
	    			}
				}
    		}
    		.answer {
    			padding: 12px 0 2px 0;
    		}
		}
	}

	.block-section {
		padding: 0 6.2% 0 7.4%;

		@media screen and (max-width: 767px) {
			padding: 0 3.3%;
		}
	}

	.spc-shipping {
		.customer-signin-section {
			padding: 26px 30px 26px;
    		margin-top: 27px;

    		@media screen and (max-width: 767px) {
    			padding: 15px 15px 20px;
    			margin-top: 20px;
    		}
		}
		.saved-address-block {
			.head-wrapper {
				padding-bottom: 17px;
    			margin: 30px 0 26px 0;

    			@media screen and (max-width: 767px) {
    				padding-bottom: 15px;
    				margin: 21px 0 12px;
    			}
			}
		}

		.billing {
			h2 {
				margin-bottom: 14px;
			}
		}

		.summary {
			h2 {
				margin: 19px 0 14px 0;
			}
		}
	}

	.spc-shipping {
	 	.checkout-tab-head {
	 		h2 {
	 			padding: 0 0 0 2px;
	 			@media screen and (max-width: 767px) {
	 				padding-top: 0;
	 			}
	 		}
		}
		.shipping {
 			h2 {
 				padding: 12px 0 0 0;
 			}
 		}

 		&.registered-shipping {
 			.shipping {
 				h2 {
 					padding: 15px 0 0 0;
 					@media screen and (max-width: 767px) {
 						padding-bottom: 0;
 					}
 				}
 			}
 			.saved-address-block {
 				padding: 0 6.4% 0 7.4%;

 				@media screen and (max-width: 767px) {
 					padding: 0 3.3%;
 				}

 				.head-wrapper {
 					margin: 30px 0 6px 0;
 				}

 				.saved-address-count {
 					margin: 7px 0 18px;

 					@media screen and (max-width: 767px) {
 						margin: 0px 0 8px;
 					}
 				}
 			}
 			.shipping-section {
 				.delivery-option-heading {
 					legend {
 						padding: 17px 0 16px 0;
 						margin-bottom: 21px;

 						@media screen and (max-width: 767px) {
 							padding: 15px 0 16px 0;
 						}
 					}

 					a {
 						margin: 4px 0 0 0;

 						@media screen and (max-width: 767px) {
 							margin: 0;
 						}
 					}
 				}
 			}
 			.address-actions {
 				.seperator {
 					margin: 0 13px;

 					@media screen and (max-width: 767px) {
 						margin: 0 6px;
 					}
 				}
 			}

 			@media screen and (max-width: 1023px) {
 				.select-address {
 					width: 100%;
 				}

 				.address-section {
 					.form-row {
 						&.useAsBillingAddress {
 							padding: 0px 0 0 2px;
    						margin-bottom: 5px;
 						}
 					}
 				}
 			}
 		}
	}

	 .form-row {
	 	margin: 0 0 19px 0;
	 }

	 .address-section {
		.form-row {
			@media screen and (max-width: 767px) {
				width: 100%;
    			padding: 0;
			}
			&.postal  {
				@media screen and (max-width: 767px) {
				    width: 50%;
    				padding-right: 6%;
    			}
			}

			&.label-inline {
				label {
					span {
						line-height: 14px;
					}
				}
			}
		}
	}

	 .shipping-section {
	 	.delivery-option-heading {
	 		legend {
	 			padding: 32px 0 16px 0;

	 			@media screen and (max-width: 767px) {
	 			    padding: 15px 0 16px 0;
	 			}
	 		}
	 	}
	 	.form-row {
	 		&.even {
	 			width: 50%;

	 			@media screen and (max-width: 767px) {
	 				width: 100%;
	 			}
	 		}
	 	}
	}

	.gift-section {
		legend {
			padding: 35px 0 16px 0;
			margin-bottom: 20px;

			@media screen and (max-width: 767px) {
			    padding: 15px 0 16px 0;
    			margin-bottom: 21px;
    		}
		}

		.gift-message-text {
			.field-wrapper {
				width: 82.8%;
			}
		}

		.gift-left {
			.form-row {
				.field-wrapper {
					margin: 1px 10px 0 4px;

					@media screen and (max-width: 767px) {
					    margin: 0 10px 0 0;
					}
				}

				&:nth-child(2n) {
					width: 44.5%;

					@media screen and (max-width: 767px) {
					   	width: 100%;
					}
				}

				&.gift-message-text {
					width: 100%;
					margin: 0 0 6px 0;

					.form-caption {
			 			margin: 0;
			 		}
				}
			}
		}

		.gift-right {
			padding: 0px 0 10px 0;
			.form-row-button {
				margin: 24px 0 0 0;
				button {
					padding: 14px 50px 15px;
				}
			}
		}
	}

	.checkout-billing, .spc-express-checkout {
		.payment-option-section {
			.tab-section {
				.right-content {
					.error-msg-content {						
					   	.error{
					   		font-size: $base-font + 2;
							@include WorkSansRegular($WorkSans);
					   	}
					}
				}
			}
		}
	}
}

.customer-signin-section {
	h2 {
		&.align-left {
			padding: 0;
		}
	}

	.head-wrapper {
		padding-bottom: 14px;
    	margin: 1px 0 15px;

    	@media screen and (max-width: 767px) {
    		margin: 11px 0 10px;
    		padding-bottom: 13px;
    	}
	}
	.edit-link {
			line-height: 24px;
			color: $black;
	}
	.checkout-guest {
		line-height: 18px;
	}
}

.address-validation-dialog {

	button {
		&.corrected-avs-button{
			margin: 0;
			padding: 13px 10px;

			@media screen and (max-width: 767px) {
				padding: 14px 19px;
			}
		}
	}

	.update-address-button {
		margin-left: 2%;

		@media screen and (max-width: 767px) {
			margin-left: 0;
		}
	}

	.address-controls {
		button {
			font-family: $HelveticaBold;
			font-size: $base-font + 2px;
			&#cancel-avs-button{
			    color: $black;
			}
			&.corrected-avs-button{
			    background: none;
			    border: none;
			    margin: 18px 19px;
			    padding: 0;
		        letter-spacing: .2px;
			}
		}
	}
}

.pobox-exclusions {
	button {
		font-family: $HelveticaBold;
		font-size: $base-font + 2px;
		&.cancel-ship-method-except {
			color: $black;
			font-size: $base-font + 2px;
		}
	}
	.pobox-items {
		.attribute {
			.price-promotion {
				.price-sales {
					color: $RedRibbon;
				}
			}
		}
	}
}


/* Later we will move this code into asset */
.chk-out-header-right-section {
	font-family: 'Helvetica Neue LT W01_65 Md';
	font-size: 12px;
	.live-chat {
		a {
			color: $black;
			div {
				color: $black;
			}
		}
	}
}
.checkout-progress-indicator {
    .progress-text, .progress-count {
    	font-family: $HelveticaMedium;
	}
	.progress-text {
		font-family: $HelveticaMedium;
	}
	.progress-count  {
		font-family: $HelveticaBold
	}
}

//login content styling
.spc-login {
	h2 {
		font-size: $base-font + 8px;
		font-family: $Helvetica;
		letter-spacing: 0;
	    &.heading{
	    	padding-bottom: 14px;
    		margin-bottom: 19px;
	    }
    }
    .checkout-tab-head{
    	h2, h1{
    		text-transform: none;
    		&.heading{
    			text-transform: capitalize;
    		}
    	}
    }

	.login-data {
		.checkout-login-email {
			font-size: $base-font + 1px;
		    font-family: $HelveticaBold;
    }
		.checkout-login-brands {
			margin-top: 5px;
			font-family: $Helvetica;
    }
}

	button:not(.intl-shipping-btn) {
		font-size: $base-font + 2px;
		font-family: $HelveticaBold;
    }
    .make-label-absolute {
		.form-row {
			&.login-rememberme {
				margin: 7.3% 0 0;

				label {
					position: static;
				    -webkit-transform: none;
				    -ms-transform: none;
				    transform: none;
				    padding: 0 0 0 11px;
			    	span {
		    	 		font-family: $Helvetica;
		    	 		font-size: $base-font + 1px !important;
		    	 		-webkit-transition: none;
					    -moz-transition: none;
					    -o-transition: none;
					    -ms-transition: none;
					    position: static;
			    		padding: 0;
					    -webkit-transform: none;
					    -ms-transform: none;
					    transform: none;
					   	margin: 0;
					}
				}
			}
		}
    }
}

.checkoutlogin {
	.col-1 {
		padding: 36px 63px 39px 63px;
		h2{
			&.heading{
				font-weight: normal;
				text-transform: none;
				padding-bottom: 14px;
				margin: 0 0 20px;
				letter-spacing: 0;
			}
		}
		.form-row{
			margin-bottom:20px;
		}
		button{
			margin-top: 2%;
			padding: 14px 48px;
		}
		#password-reset{
		    margin: 13px 2px 14px;
		}
	}
	.col-2 {
		padding: 24px 63px 49px 61px;
		.form-row{
			margin: 0 0 19px 0;
		}
		.formbuttonrow{
			margin: 11% 0 3.1%;
		}
		h2.heading {
			margin-bottom: 14px;
    	}
    	button{
    		padding: 15px 45px;
    	}
		.login-box {
			p {
				font-size: $base-font + 1px;
    			line-height: 21px;
    			margin: 2px 0 14px 0;
    			display: inline-block;
    			font-family: $Helvetica;
		    }
		}
		.form-row {
			&.newsletter-signup {
				label {
					-webkit-transform: none;
				    -ms-transform: none;
				    transform: none;
				    margin: 0 0 0 11px;
				    width: 87%;
					 span {
						 font-family: $Helvetica;
				    	 font-size: $base-font + 1px;
					}
	    		}
			}
		}
	}
}


.saved-address-block {
	.head-wrapper {

	}
	.shipping-address-save {
		color: $black;
	}
}
.items-in-bag{
	&.saved-address-block {
		.head-wrapper {
			margin: 57px 0 11px;
		}
	}
}
//save, cancel, edit buttons
.address-actions,.newcreditcard {

	color: $black;

	span {
		color: $black;
	}

	a {
		color: $black;
	}
}
.address-actions{
    margin-top: 9px;
}


.checkout-order-summary {

    .review-disclaimer {
    	font-size: $base-font;
    	font-family: $Helvetica;
    	line-height: 18px;

    }

    .form-row {
    	margin: 13px 0 19px;


	    .button-fancy-large {
		    font-size: $base-font + 2px;
		    font-family: $HelveticaMedium;
	    }
    }

    .secure-message {
    	font-size: $base-font;
    	font-family: $Helvetica;
    	text-align: left;

    }
}

.checkout-order-totals {
	padding: 20px 20px 25px 23px;

	.summary-heading {
		font-size: $base-font + 8px;
		text-transform: capitalize;
		@include WorkSansSemiBold ($WorkSans);
		font-family: $Helvetica;
		font-weight: normal;
		letter-spacing: 0;
	}

	.order-detail {
		padding: 0 0 12px 0;

		.label {
			font-size: $base-font + 2px;
			font-family: $Helvetica;
			text-transform: capitalize;

			.shippingoverlay-link {
				background: url(../../../images/tips.svg) no-repeat;
				width:16px;
				height:16px;
				background-size:cover;
				vertical-align: bottom;
			}
		}

		.value {
			font-size: $base-font + 2px;
			font-family: $Helvetica;
			padding-right: 2px;
    		box-sizing: border-box;
    		-webkit-box-sizing: border-box;
 	 		-moz-box-sizing: border-box;
		}

		&.order-total {
			padding: 18px 0 10px 0;
			margin-top: 9px;

			.label, .value {
				font-size: $base-font + 8px;
				font-family: $Helvetica;
				font-weight: normal;
			}
			.value{
				font-family: $HelveticaBold;
			}

			&.order-total-remaining {
				padding: 0 0 12px 0;
				margin-top: 0;

				.label {
					font-size: $base-font + 2px;
					font-family: $Helvetica;
					text-transform: capitalize;
				}

				.value {
					font-size: $base-font + 2px;
					font-family: $Helvetica;
					padding-right: 2px;
		    		box-sizing: border-box;
		    		-webkit-box-sizing: border-box;
		 	 		-moz-box-sizing: border-box;
				}
			}
		}

		&.order-discount {
			&.discount {
				.value, .label {
					font-size: $base-font + 2px;
				}
			}
		}

		&.remaining-total {
			padding: 18px 0 10px 0;
			margin-top: 9px;

			.label, .value {
				font-size: $base-font + 8px;
				font-family: $Helvetica;
				font-weight: normal;
			}

			.value{
				font-family: $HelveticaBold;
			}
		}

		&.discount {
			.value, .label {
				font-size: $base-font;
			}
		}
	}
}


.spc-billing{
	.customer-signin-section{
		padding: 25px 31px 28px;
	    margin-top: 29px;
	}
	.checkout-tab-head{
		.section-heading{
			margin: 35px 0 15px 0;
		}
		.details{
			.inner-block{
				.address{
					padding: 16px 0 0 4px;
					line-height: 22px;
					.default{
						padding: 0 0 3px 0;
					}
				}
				.info{
					&.giftmessage{
						padding-top: 9px;
					}
				}
			}
		}
	}
	.checkout-tab{
		&.billing{
			.checkout-tab-head{
				.section-heading{
					margin: 42px 0 17px 0;
				}
				.saved-address-block{
					.head-wrapper{
						margin: 36px 0 0 0;
					}
				}
			}
			.saved-address-block{
				.head-wrapper{
					margin: 35px 0 0 0;
				}
				.saved-addr-dropdown-section{
					.form-row-button{
						margin: 0;
					}
				}
				.saved-address-count{
					margin-top: 14px;
				}
			}
		}
	}
	.billing-cards-section{
		.payment-method-options{
			.form-row{
				label{
					line-height: 14px;
				}
				&.label-inline{
					margin-left: 4px;
				}
			}
		}
		.form-row-button{
			margin: 0 0 14px 0;
		}
		.payment-method{
			padding-top: 1px;
			.billingfields{
				.enter-card-detials-wrapper{
					.left-cont{
						width: 50%;

						.form-row {
							.custom-select {
								.selected-option {
									&:before {
										right: 2px;
									}
								}
								select {
									background-position: right 5px center;

									@media screen and (max-width: 767px) {
										background-position: right 13px center;
									}
								}
							}
						}
					}
					.right-cont{
						max-width: 302px;
    					width: 46%;

    					.payment-card-section{
    						.heading{
    							line-height: 10px;
    						}
    					}
    					.billing-payment-description{
    						.payment-cards{
    							padding: 18px 0;
    							img {
    								padding-right: 11px;
    							}
    						}
    					}
					}
				}
			}
		}
	}
}
.checkout-billing{
	.form-row-button{
		&.billing-page-continue{
			button:not(.btn-ds){
				padding: 14px 35px 15px;
				.paypal-text {
					font-family: $Helvetica;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.pt_checkout {
		.items-in-bag {
			&.saved-address-block {
				.head-wrapper {
					margin: 26px 0 11px;

					.address-actions {
					    margin-right: 28px;
						margin-top: 5px;
					}
				}
			}
		}
	}

	.spc-summary {
		.checkout-tab-head {
			.customer-signin-section {
			    padding: 26px 33px 27px;
				margin-top: 27px;
			}
		}

		.checkout-tab {
			&.shipping {
				.checkout-tab-head {
					.section-heading {
						margin: 36px 0 15px 0;

						.edit-link {
						    padding-right: 28px;
							line-height: 31px;
						}
					}

					.details {
						padding: 35px 5.2% 70px 7.4%;

						.inner-block {
							h3 {
								padding: 0 0 15px 0;
							}
						}
					}
				}
			}

			&.billing {
				.checkout-tab-head {
					.section-heading {
						margin: 36px 0 22px 0;

						.edit-link {
						    padding-right: 28px;
							line-height: 30px;
						}
					}

					.details {
						padding: 37px 5.2% 71px 7.3%;

						.billing-left-section {
							.mini-payment-instrument {
								margin-top: 15.6%;

								.detail {
									span{
										&.img {
											margin-top: 10px;
										}
									}
								}
							}
						}
					}
				}
			}

			&.review {
				.checkout-tab-head {
					.section-heading {
						margin: 35px 0 14px 0;
					}
				}

				.place-order {
					.order-summary-footer {
						padding: 20px 0 22px 27px;

						.left-section {
							.review-disclaimer {
								line-height: 18px;
							}

							.submit-order {
								.form-row {
									.button-fancy-large {
										margin-top: 10px;
										padding-top: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.spc-summary, .spc-billing, .spc-express-checkout {
	.checkout-tab-head {
		.section-heading {
    		 .edit-link {
    		 	font-family: $HelveticaBold;
    		 	font-size: $base-font;
    		 	color: $black;
    		 }
		}

		.details {
			.inner-block {
			    font-family: $Helvetica;

			    h3 {
			    	font-family: $Helvetica;
			    }

			    .address {
				    font-size: $base-font + 1px;

				    .default {
				    	font-family: $HelveticaBold;
				    }
			    }

			    .minishipments-method {
			    	font-size: $base-font + 1px;
			    	font-family: $Helvetica;

			    	.option-heading {
			    		font-family: $HelveticaBold;
			    	}

			    	.promo {
			    		font-size: $base-font;
			    	}
			    }

			    .info {
			    	font-family: $Helvetica;
			    	font-size: $base-font + 1px;

			    	.label {
			    		font-family: $HelveticaBold;
			    	}
			    }
    		}
		}
	}
}

.billing-left-section, .billing-right-section {
    .mini-billing-address, .mini-payment-instrument, .reward-certificate, .promo-code, .gift-card {
    	font-family: $Helvetica;

    	h3 {
    		font-size: $base-font + 8px;
    		font-family: $Helvetica;
    	}

    	.detail {
		    font-size: $base-font + 1px;

		    .cc-owner {
				font-family: $HelveticaBold;
		    }
    	}
    }
}

.place-order {
	.order-summary-footer {
    	.left-section, .right-section {
    		font-family: $Helvetica;

    		.review-disclaimer {
    			font-size: $base-font;
    		}

    		.submit-order {
			    .form-row {
			    	.button-fancy-large {
			    		font-family: $HelveticaBold;
    					letter-spacing: 2px;

			    		@media screen and (max-width: 1024px){
			    			width: 270px;
			    		}

			    		@media screen and (max-width: 767px){
			    			width: 100%;
			    			margin: 0;
			    			padding: 14.5px 30px;
			    		}
			    	}
			    }
    		}

    		.secure-message {
    			font-size: $base-font;
    		}
		}
		.left-section {
			fieldset {
				.form-row {
					button {
						color: $white;
						font-family: inherit;
					}
				}
			}
		}
	}
}

.qa-section{
	.question-title{
		font-family: $HurmeBlack;
	    letter-spacing: 3px;
	    text-transform: uppercase;
		.answer{
			 @include WorkSansMedium($WorkSans);
		}
	}
}

.billing-cards-section {
	.checkout-rebuttal {
		.acquisition-rebuttal {
			color: $black;
		}
	}
}

@media screen and (max-width: 1024px) {
	.checkoutlogin {
		.col-2 {
			padding:7px 20px 31px;
			.login-box {
				p {
					font-size: $base-font + 1px;
   				 }
   			 }
		}
    }
}
@media screen and (max-width: 1023px) {
	.pt_checkout {
		.qa-section {
			.qa-content {
				padding: 25px 17px 20px 30px;

				&:nth-child(2n), &:nth-child(3n), &:nth-child(4n), &:nth-child(n) {
					padding: 25px 17px 20px 30px;
				}
			}
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.billing-cards-section{
		.payment-method{
			.billingfields{
				.cvn{
					width: 50%;
				}
				.enter-card-detials-wrapper{
					.left-cont{
						.cvn{
							.field-wrapper{
								width: 92%;
							}
						}
					}
				}
			}
		}
	}
	.checkoutlogin{
		.col-1{
			padding: 30px 20px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.pt_checkout {
		&#wrapper{
			#main{
				//margin-left: 10px;
				//margin-right: 10px;
			}
		}
	    .cart-row{
	    	.column{
	    		&.col-2{
		    		.custom-cart-qty{
		    			margin-top: 47%;
		    		}
	    		}
	    		&.col-3{
	    			width: 22.8%;
	    		}
	    	}
	    }
	    #secondary{
	    	&.nav{
	    		margin: 0 10px;
	    	}
	    	.checkout-order-totals{
	    		padding: 20px 20px 33px 19px;
	    		.summary-heading{
	    			font-size: 24px;
	    			margin: 0 0 18px;
	    		}
	    		.order-detail{
	    			&.order-total{
	    				padding: 25px 0 10px 0;
    					margin-top: 12px;

	    				.label, .value{
	    					font-size: 24px;
	    				}

	    				&.order-total-remaining {
	    					padding: 0 0 12px 0;
							margin-top: 0;

		    				.label, .value{
		    					font-size: $base-font + 2px;
		    				}
	    				}
	    			}

	    			&.remaining-total {
	    				padding: 25px 0 10px 0;
    					margin-top: 12px;

	    				.label, .value{
	    					font-size: $base-font + 12px;
	    				}
	    			}
	    		}
	    	}
	    }
	}
	.checkoutlogin {
		.col-2 {
			padding: 24px 41px 49px 39px;
		}
		.col-1{
			padding: 36px 41px 39px 39px;
			.spc-login-btn{
				margin: 6px 0 9px;
			}
			#password-reset{
				text-align: right;
				margin: 4px 0px 14px;
				width: 100%;
			}
		}
		.col-seperator{
			display: block;
		}
    }
    .address-actions{
    	margin-right: 0px;
    }
    .billing-cards-section{
		.payment-method{
			.billingfields{
				.cvn{
					width: 46%;
				}
				.enter-card-detials-wrapper{
					.left-cont{
						.cvn{
							.field-wrapper{
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.checkout-tabs{
		&.spc-shipping {
			.customer-signin-section {
				margin-top: 28px;
			}
			.checkout-tab {
				&.shipping {
					.checkout-tab-head {
					 	h2 {
					 		margin-top: 28px;
					 	}
					}
				}
			}
			.block-section {
				&.shipping-section {
					legend {
						padding-top: 25px;
					}
					.form-row {
						&.even {
							width: 49%;
						}
					}
				}
			}
		}
	}

	.spc-billing{
		.billing-cards-section{
			.payment-method{
				.billingfields{
					.enter-card-detials-wrapper{
						.left-cont{
							width: 44%;
						}
					}
				}
			}
		}
	}

	.spc-summary{
		.checkout-tab{
			&.review{
				.place-order{
					.order-summary-footer{
						.left-section{
							.submit-order{
								.form-row{
									.button-fancy-large{
										padding-top: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_checkout {
		.gift-section{
    		.gift-right{
    			.form-row-button{
    				button{
    					padding: 15px 30px 14px;
    				}
    			}
    		}
    	}
		.spc-login{
			&.checkout-tabs{
				.checkout-tab{
					&.login{
						.checkout-tab-head{
							h2,h1{
								padding-bottom: 25px;
							}
						}
						.checkout-login-email{
							line-height: 18px;
						}
						.checkout-login-brands{
							margin-top: 9px;
							line-height: 18px;
						}
					}
					&.shipping, &.billing, &.summary{
						.checkout-tab-head{
							h2{
								padding-bottom: 22px;
								margin-bottom: 19px;
							}
						}
					}
					.checkoutlogin{
						padding: 24px 0 24px;
						.col-1{
							padding: 24px 10px 30px;
							h2{
								&.heading{
									padding-bottom: 16px;
								}
							}
						}
					}
				}
			}
			.form-row{
				.field-wrapper{
					input[type="text"]{
						padding: 19px 15px 13px 15px;
					}
				}
				&.password{
					.field-wrapper{
						input[type="text"]{
							padding: 20px 15px 14px 15px;
						}
					}
				}
			}
			.login-rememberme{
				margin-top: 0;
			}
			.col-1{
				#password-reset{
					margin: 19px 11px 7px 0px;
				}
				.spc-login-btn{
					padding: 14px 51px 15px;
					margin: 10px 0 0 0;
				}
				button{
					&.oAuthIcon{
						margin-top: 15px;
					}
				}
			}
		}
		.items-in-bag{
			&.saved-address-block{
				.head-wrapper{
					margin: 11px 0 18px;
					.address-actions{
						float: right;
						width: auto;
					}
				}
			}
		}
		.saved-address-block{
			.address-actions{
				 .edit-section{
				 	.seperator{
				 		display: inline-block;
				 	}
				 }
			}
		}
		.cart-columns{
			padding-top: 26px;
		}
		.cart-row{
			.column{
				&.col-1{
					.item-details{
						.name{
							margin: 0 0 15px;
						}
						.attribute{
							margin-bottom: 8px;
						}
						.sku{
							margin: 0 0 14px 0;
						}
					}
				}
			}
			.columns-combine{
				.column{
					&.col-2{
						text-align: left;
						margin: 6px 0;
					}
					&.col-3{
						width: 100%;
    					max-width: 100%;
    					.item-total{
    						margin: 10px 0 16px 0;
    					}
					}
				}
			}
		}
		.checkout-order-totals{
			padding: 18px 10px 18px 10px;
			.summary-heading{
				margin: 0 0 18px;
			}
			div{
				&.order-detail{
					padding: 0 0 14px 0;
					&.order-total{
						margin-top: 2px;
						padding-top: 22px;

						&.order-total-remaining {
							padding: 0 0 14px 0;
						}
					}

					&.remaining-total {
						margin-top: 2px;
						padding-top: 22px;
					}
				}
			}
		}
		.shipping-section {
			.form-row {
				.field-wrapper {
					width: 3%;
					input[type="radio"] {
						&:checked {
							+.custom-radio-icon {
								top: 0;
								left: 0;
							}
						}
					}
					.custom-radio-icon {
						top: 0;
						left: 0;
					}
				}
				.field-desc {
					width: 94%;
				}
			}
		}
		.form-row{
			.field-wrapper{
				input[type="text"]{
					font-size: 14px;
				}
			}
		}
		.qa-section {
			.question-title {
				padding: 25px 0;
			}

			.qa-content {
				padding: 21px;

				&:nth-child(2n), &:nth-child(3n), &:nth-child(4n),&:nth-child(n) {
					padding: 21px;
				}
			}
		}
	}
	.checkout-order-totals{
		.summary-heading{
			font-size: 24px;
		}
	}
	.checkoutlogin{
		.col-2{
			padding: 25px 10px 7px;
			.login-box{
				p{
					margin: 0px 0 15px 0;
				}
			}
			.form-row{
				margin: 0 0 21px 0;
			}
		}
	}
	.checkout-billing{
		.payment-option-section{
			.tab-section{
				.right-content{
					button{
						//padding: 12px 20px;
					}
				}
			}
		}
	}
	.spc-billing{
		.checkout-tab.billing{
			.saved-address-block{
				.head-wrapper{
					margin: 17px 0 0;
				}
			}
		}
		.billing-cards-section{
			.payment-method{
				.billingfields{
					.enter-card-detials-wrapper{
						.left-cont{
							width: 100%;
						}
					}
				}
			}
		}
		.billing-cards-section{
			.payment-method{
				.billingfields{
					.enter-card-detials-wrapper{
						.right-cont{
							width: 100%;
							max-width: 100%;
						}
					}
				}
			}
		}
		.customer-signin-section{
			padding: 15px 15px 20px;
			margin-top: 20px;
		}
	}
	.checkout-billing{
		.form-row-button{
			&.billing-page-continue{
				button:not(.btn-ds) {
					padding: 14.5px 20px;
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.pt_checkout {
		.shipping-section {
			.form-row {
				.field-wrapper {
					width: 5%;
				}
				.field-desc {
					width: 90%;
				}
			}
		}
	}
}

@media screen and (min-width: 1260px) {
	.pt_checkout{
		.gift-section{
			.gift-message-text{
				.field-wrapper{
					width: 91%;
				}
			}
		}
	}
}

@media screen and (max-width:1023px){
	.checkout-order-summary{
		.form-row{
			width:auto;
			float:none;
			 .button-fancy-large{
				 float:none;
			 }
		}
	}
}

#secondary{
	.checkout-order-summary{
		.order-summary-footer{
			.form-row{
				.add-to-email-list{
					.add-to-emaillist-warningmsg{
						.arrow-up{
							@media screen and(min-width:768px) and (max-width:1024px){
									left:35px;
							}
							@media screen and(width:1024px) and (orientation:landscape){
								left:0px;
							}
						}
					}
                }
            }
        }
    }
}

.defer-code-error{
	.error{
   		font-size: $base-font + 2;
		@include WorkSansRegular($WorkSans);
		color: $medium-carmine;
   	}
}