$sans-serif: "Inter Tight", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$serif: TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif;

$Helvetica : $sans-serif;
$HelveticaBold: $sans-serif;
$HelveticaMedium: $sans-serif;
$Lora: $sans-serif;
$WorkSans: $sans-serif;
$sans-serif-alt: $sans-serif;
$BauerBodoniRegular: $sans-serif;
$ImperialRegular: $serif;
$ImperialMedium: $serif;
$HurmeBlack: "Lexend", $sans-serif;
$HurmeLight: "Lexend", $sans-serif;
$Hurme: "Lexend", $sans-serif;
$HurmeSemiBold: "Lexend", $sans-serif;
$HurmeBold: "Lexend", $sans-serif;