.product-tile.product-brylane-category {
	width: 307px;
	@media screen and (max-width: 1200px){
		width:245px;
	}
}
.product-tile {
	padding: 0 0 17px 0;
	.quickview {
	    width: 91%;
		width: calc(100% - 20px);
		height: 50px;
	    padding: 15px 0.5em;
	    transform: translate(-50%);
	    color: $black;
	    font-family: $HurmeBlack;
	    letter-spacing: 3.5px;
		border: 2px solid $black;
		opacity: 0.9;
	}

	.product-image.product-brylane {
		img { 
			object-fit: contain; //(doesn't work in regular IE :(... )
			width: 240px;
			height: 346px;
			text-indent: -9999px;
    		max-height: 100%;
			@media screen and (max-width: 1260px) {
				width: 240px;
				height: 346px;
			}
			@media screen and (max-width: 767px) {
				width: 100%;
				height: auto;
				vertical-align: middle;
			}
		}
		
		.b_product_badge {
			top: 0;
			span {
				text-align: left;
			}
			img {
				width: auto;
				height: auto;
				vertical-align: top;
			}
		}
	}


	.product-image.product-brylane-category {
		img {
			width: 300px;
			height: 300px;
			text-indent: -9999px;
			max-height: 100%;
			@media screen and (max-width: 1260px) {
				width: 240px;
				height: 240px;
			}
			@media screen and (max-width: 767px) {
				width: 140px;
				height: 140px;
			}
		}
		
		.b_product_badge {
			top: 0;
			img {
				max-width: 50px;
				max-height: 50px;
				width: auto;
				height: auto;
				vertical-align: top;
			}
		}
	}	

	.product-name {
		overflow: auto;
		font-family: $HurmeSemiBold;
		text-transform: uppercase;
    	font-size: $base-font;
		letter-spacing: 0.5px;
		color: $black;
	}

	.product-brand {
		@include WorkSansRegular($WorkSans);
		text-transform: uppercase;
		font-size: $base-font - 2px;
		color: $brand-name-color;
		letter-spacing: 0.2px;
		margin-top: 0.5em;
	}

	.product-pricing {
		font-family: $WorkSans;
		font-size: $base-font + 1px;
		.product-standard-price {
			color: $manatee;
		}
		.product-sales-price {
			color: $fire-engine-red;
			letter-spacing: 0.2px;
			display: inline-block;
			&.product-actual-price {
				color: $black;
			}
		}
	}
	.product-promo {	
		font-size: 0.8em;

		.promotional-message {
			@include WorkSansSemiBold($WorkSans);
			color: $fire-engine-red;
			font-size: $base-font;
		}
	}
	.clearence-msg {
		font-size: $base-font;
	}
	.product-feature-messages {
		font-size: $base-font - 2px;
	}
	.product-special-messages {
		@include WorkSansBold($WorkSans);
		font-size: $base-font;
		text-transform: uppercase;
		letter-spacing: 0.3px;
		color: $special-product-messages-color;
	}
	.product-swatches {
		display: block;
		width: 90%;
		margin-left: auto;
		margin-right: auto;

		ul {
			display: table;
			padding: 0;
			text-align: center;
			margin: 0 auto;
			max-width: 201px;
			li {
				padding: 2px 1px 2px 1px;
			}
		}
		.swatch {
			width: 19px;
			min-width: 19px;
			height:19px;
			border-radius: 20px;

			img {
				border-radius: 20px;
			}
		}
		@media screen and (max-width:767px) {
			width: 92%;
		}
		.product-swatches-all, .product-swatches-all-showless {
			font-family: $WorkSans;
			font-size: $base-font - 1px;
			color: $black;
		}
	}
	.product-swatches {
        display: block;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        
        @media screen and (max-width:767px) {
        	width: 92%;
        }

        .hideswatch {
            position: relative;
        }

        &.show-color {
        	.product-swatches-all {
                visibility: hidden;
        	}
        	.hideswatch {
                display: inline-block;
			}
        }
        ul {
        	display: table;
    		text-align: center;
    		width: 100%;
    		margin: 0 auto;
    		max-width: 207px;
    		li {
			    display: inline-table;
			    float: none;
                padding: 2px 1px 2px 1px;
			    &.hideswatch {
                    display: none;
			    }
			}
        }
        .product-swatches-all, .product-swatches-all-showless {
		    font-family: $WorkSans;
		    font-size: 11px;
    		letter-spacing: 1px;
		    text-transform: uppercase;
		    display:inline;
		}
		.product-swatches-all {
			top: 45px;
		}
		.swatches-six {
			margin-top: 7px;
		}
		.swatches-five {
			margin-top: 7px;
		}
		.swatches-four  {
			margin-top: 7px;
		}
		li.hideswatch:nth-child(-n+7) {
			display: inline-block;
		}
		&.desktop-top-buffer {
			margin-top: -14px;
		}
	}
}

.search-result-items {
	.grid-tile {
		&.two-space-tile {
		    background: $light-skygrey;

		}

		span {
			&.text1 {
				@include WorkSansMedium($WorkSans);
				font-size: 15px;
				letter-spacing: 2px;
				text-transform: uppercase;
			}
			&.text3 {
				@include LoraItalic($Lora);
				font-size: 24px;
			}
		}

		.text-info {
			position: absolute;
			bottom: 11%;
			text-align: center;
			color: $white;
			width:100%;
		}

		.limited-off-tile {
			h1 {
				@include WorkSansBlack($WorkSans);
				letter-spacing: 5px;
				font-size: 70px;
				padding-left: 2%;
				margin: 0 0;
				line-height: 64px;
				span {
					@include WorkSansThin($WorkSans);
					font-size: 70px;
					letter-spacing: 20px;
					text-transform: uppercase;
				}
			}
		}

		.clearance-off-tile {
			.text-info {
			    bottom: auto;
    			top: 25%;
			}

			h1 {
				@include WorkSansBlack($WorkSans);
				letter-spacing: 5px;
				letter-spacing: 15px;
				font-size: 115px;
				line-height: 100px;
				margin: 0;
			}
			h2 {
				@include WorkSansThin($WorkSans);
				font-size: 115px;
			    letter-spacing: 30px;
				line-height: 100px;
				margin:0 0 3px 0;
			}
		}

		&.one-space-tile {
			background: $light-pink;
			background: -moz-linear-gradient(-45deg,  $light-pink 0%, $light-pink 39%, $light-blue 73%, $light-blue 100%);
			background: -webkit-linear-gradient(-45deg,  $light-pink 0%,$light-pink 39%,$light-blue 73%,$light-blue 100%);
			background: linear-gradient(135deg,  $light-pink 0%,$light-pink 39%,$light-blue 73%,$light-blue 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$light-pink', endColorstr='$light-blue',GradientType=1 );


			.onespace-tile-content {
				margin-top: 34%;
			}

			span {
				font-size: $base-font + 4px;
				@include LoraItalic($Lora);
			}

			h1 {
				font-size: 30px;
				@include WorkSansBold($WorkSans);
			    margin: 4px 0 16%;
    			line-height: 33px;
			}

			p {
				 @include WorkSansMedium($WorkSans);
				font-size: $base-font + 2px;
			}

			button {
				font-size: $base-font + 2px;
				@include WorkSansSemiBold($WorkSans);
				background: $black;
		        width: 78%;
			    margin-top: 38px;
			}

		}
	}
}

@media screen and (max-width: 1024px) {
	.product-tile {
		.product-swatches {
			ul {
    			max-width: 180px;
			}

			li.hideswatch:nth-child(-n+7) {
                display: none;
			}

			li.hideswatch:nth-child(-n+5) {
                display: none;
            }
            li.hideswatch:nth-child(-n+6) {
                display: inline-block;
            }

            &.show-color {
                li.hideswatch:nth-child(-n+7) {
                    display: inline-block;
                }
            }

            &.tablet-top-buffer {
                margin-top: -18px !important;
            }
		}
	}
}


@media screen and (max-width:767px) {
	.product-tile {
	     .product-name {
	    	font-size: $base-font + 1px;
	    }
	    .product-pricing{
	    	font-size:$base-font + 2px;
	    }
	    .product-promo{
	    	.promotional-message{
	    		font-size:$base-font;
	    	}
	    }
		.product-swatches {
			ul {
				max-width: 145px;
			}
			
			.swatch {
				width: 16px;
				max-width: 16px;
				min-width: 16px;
				height: 16px;
			}

			li.hideswatch:nth-child(-n+7) {
                display: none;
			}
			li.hideswatch:nth-child(-n+6) {
                display: none;
			}
			li.hideswatch:nth-child(-n+5) {
                display: inline-block;
		    }
		    li.hideswatch:nth-child(6) {
                display: none;
			}
			&.show-color {
		    	li.hideswatch:nth-child(6) {
                    display: inline-block;
				}
			}

            &.mobile-top-buffer {
                margin-top: -18px !important;
		    }
	    }
	}

	//content tile design
	.search-result-items {
		.grid-tile {
			.text-info{
				bottom: 9%;
			}
			&.one-space-tile {
				p {
					font-size: 12px;
				}
			}
			&.two-space-tile {

				.limited-off-tile{
					h1{
						font-size: 42px;
						line-height: 42px;
						span{
							font-size: 42px;
						}
					}
				}
				h1 {
					font-size: 70px;
					line-height: 60px;
				}
				h2 {
					font-size: 70px;
					line-height: 60px;
				}
				.clearance-off-tile{
					.text-info{
						top: 25%;
					}
				}
			}
			span{
				&.text1{
					font-size: 9px;
				}
				&.text3{
					font-size: 15px;
				}
			}
		}
	}
}


@media screen and (max-width:480px) {
	.search-result-items {
		.grid-tile {
			&.one-space-tile {




			}

			&.two-space-tile {
				.limited-off-tile {
					h1 {
						padding-left: 2%;
					}
				}

				.clearance-off-tile {
					h2 {
						margin: 0 0 10px 10px;
					}
				}
			}
		}
	}
}