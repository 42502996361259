@import "../common/variables";
@import "../common/mixin";
@import "font";
@import "variables";

.gift-heading {
	font-family: $WorkSans;
	font-weight: 700;
	font-size: $base-font + 24px;
	text-align: center;
    letter-spacing: 2.5px;
    width: 57%;
    display: block;
    margin: 0 auto;
    
    @media screen and (max-width: 767px) {
    	font-size: $base-font + 6px;
    }
}

.gift-paragraph {
	@include WorkSansMedium($WorkSans);
	font-size: $base-font + 2px;
	letter-spacing: .6px;
    text-align: center;

	@media screen and (max-width: 767px) {
    	font-size: $base-font;
    }
}

.gift-card-section {
	.gift-card-result-section {
		box-shadow: -16px 16px 0 $black;
		display: inline-block;
		width: 100%;
	}

	.note-section {
		box-shadow: -16px 16px 0 $black;
		
		.note {
			 @include WorkSansMedium($WorkSans);
			font-size: $base-font + 2px;
			color: $dim-gray;
		}
		
		.another-card {
			color: $spicy-pink;
			@include WorkSansSemiBold($WorkSans);
			font-size: $base-font + 2px;
		}
	}
	
	.gift-card-message, .gift-card-lookup-form-section {
		box-shadow: -16px 16px 0 $black;
	}
	
	.make-label-absolute {
		label {
			span {
				@include WorkSansSemiBold($WorkSans); 
				font-size: $base-font;
			}
		}
	}
	
	.balance-result-section {
		box-shadow: -16px 16px 0 $black;
		
		.balance-result-block {
			background: $black;
			@include WorkSansSemiBold($WorkSans);
			font-size: $base-font + 1px;
	    }
		
		.svs-card-amount {
			font-size: $base-font + 20px;
			font-weight: 700;
		}
		
		.svs-masked-number {
			font-size: $base-font + 2px;
		}
		
		.available-balance-text {
		    margin: 0 0 15px 0;
		}
	}
	
	.form-action {
		button {
			background: $black;
			color: $white;
			border-color: $black;
			@include WorkSansSemiBold($WorkSans);
			font-size: $base-font + 2px;
		}
	}
	
	@media screen and (max-width: 767px) {
		.gift-card-message, .gift-card-lookup-form-section, .gift-card-result-section {
	    	box-shadow: -10px 10px 0 $black;
		}
		
		.gift-heading {
		    width: 91%;
		    font-size: $base-font + 12px;
		    margin: 0 auto;
		}
		
		.balance-result-section {
	    	box-shadow: -10px 10px 0 $black;
	    	
			.svs-card-amount {
				font-size: $base-font + 12px;
			}
			
			.balance-result-block {
				font-size: $base-font - 2px;
			}
		}

		.note-section {
	    	box-shadow: -10px 10px 0 $black;
	    	
	        .note {
	        	font-size: $base-font;
        	    text-align: center;
	        }
		}
		
		.gift-paragraph {
			font-size: $base-font;
		}
	}
}

.plcc-pre-approval-points {
	font-family: 'Lora', serif;
	font-size: 20px;
	color: $black;
	background: #75de9f;
	text-align: center;	
	display: inline-block;
	width: 100%;
	padding: 8px;
	box-sizing: border-box;
	
	@media screen and (max-width: 767px) {
	    font-size: $base-font - 2px;
	}
}

.plcc-main {
	&.plcc-pre-approval-main {
		.plcc-main-heading {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font + 6px;
			color: $black;
		    margin: 0 0 15px 0;
		}
		
		.plcc-profile-summary {
		    margin: 0 0 15px 0;
			
			.plcc-profile-name , .plcc-profile-address,.plcc-city, .plcc-profile-email, .plcc-edit-profile {
				@include WorkSansMedium($WorkSans);
				font-size: $base-font;
			}
			
			.plcc-profile-name , .plcc-profile-address,.plcc-city, .plcc-profile-email {
				color: $dim-gray;
			    line-height: 25px;			
			}
			
			.plcc-edit-profile {
				color: $black;
			    line-height: 19px;
		        text-decoration: underline;
			}
		}
		
		.phone-number-msg {
			clear: both;
		}
		
		.question-mark-icon {
		    margin-right: 15px;
		}
		
		.plcc-form-section {
			width: 92.1%;
			padding: 38px 70px 38px 28px;
		    background: $white-smoke-light;
		    box-sizing: border-box;
		    margin-bottom: 20px;
		    display: inline-block;
			
			.plcc-masked-number {
				width: 32%;
				background: $white;
			    display: inline-block;
			    padding: 18px 15px 14px 15px;
			    box-sizing: border-box;
				@include WorkSansMedium($WorkSans);
				font-size: $base-font + 2px;
				color: $black;
			}
			
			.plcc-form-row-wrapper {
				display: inline-block;
				width: 100%;
			}
			
			.form-row {
				&.ssn {
				    width: 68%;
    				float: right;
				}
			}  
		}
		
		.plcc-primary {
			label {
				@include WorkSansMedium($WorkSans);
				font-size: $base-font;
				color: $dim-gray;
			}
			
			.plcc-ssn-label, .extra-label {
				color: $black;
			}
			
			.phone-number-msg, .child-support-section , .married-section, .important-info {
				clear: both;
			}
			
			.extra-label, .plcc-personal-address-section {
				font-size: $base-font;
				text-transform: uppercase;
			}
			
			.plcc-personal-address-section {
				@include WorkSansMedium($WorkSans);
				font-size: $base-font;
				color: $black;
			    margin: 0 0 15px 0;
			}
		}
	}
	
	.plcc-secondary {
		.see-benefits {
			@include WorkSansSemiBold($WorkSans);
		    font-size: $base-font + 2px;
		    color: $black;
		}
	}
	
	.option-text {
	    @include WorkSansMedium($WorkSans);
		font-size: $base-font;
    }
	
	.plcc-left-nav-heading {
		@include WorkSansSemiBold($WorkSans);
		font-size: $base-font + 6px;
	}
	
	.plcc-left-nav-info {
		 @include WorkSansMedium($WorkSans);
		font-size: $base-font + 2px;
		color: $dim-gray;
		line-height: 22px;
	}
	
	.plcc-primary {
		.phone-number-msg, .child-support-section , .married-section, .important-info {
			 @include WorkSansMedium($WorkSans);
			font-size: $base-font;
			color: $dim-gray;
			line-height: 18px;
			
			.bold {
				font-weight: 700;
			}
		}
		
		.phone-number-label {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font;
			color: $black;
		}
		
		.extra-label {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font + 1px;
			color: $dim-gray;
		}
		
		.plcc-ssn-label {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font;
			color: $slightblack;
		}
		
		.note-section {
			@include WorkSansSemiBold($WorkSans);
		    font-size: $base-font + 2px;
		    color: $black;
		    border: 2px solid $black;
		    padding: 20px;
		}
		
		.review-form-error-msg {
			@include WorkSansSemiBold($WorkSans);
		    font-size: $base-font;
		    color: $plcc-error;
		    margin-bottom: 10px;
            margin-top: 10px;
		}
		
		.form-action {
			.form-row-button {
				&.cancel-button {					
					button {
						padding: 19px 23px;
					}
				}
			}
		}
		
		.form-row {
			&.form-indent {
		    	label {
		    		@include WorkSansMedium($WorkSans);
		    		font-size: $base-font + 1px;
		    		color: $black;
	    		    line-height: 20px;
		    	}
		    }
		}
		
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea,input[type="tel"]  {
				@include WorkSansMedium($WorkSans);
	    		font-size: $base-font + 2px;
	    		color: $slightblack;	
			}
		}
		
	}
	
	@media screen and (min-width: 1024px) and (max-width: 1399px) {
		padding: 0 10px;
		box-sizing: border-box;
		
		&.plcc-pre-approval-main {
			.plcc-form-section {
			    width: 97.1%;
    			padding: 38px 3% 38px 2%;
			}
		}
	}
	
	@media screen and (min-width: 768px) and (max-width: 1023px) {
		&.plcc-pre-approval-main {
			width: 708px;
			margin: 22px auto 0;
			display: block;
			
			.plcc-main-heading {
				display: none;
			}
			
			.plcc-secondary {
				max-width: 100%;
			
				.see-benefits {
					margin-bottom : 20px;
				}
			}
			
			.custom-select {
			    font-size: 0;
			}
			
			.plcc-primary {
				padding: 0;
				
				.phone-number-msg,.child-support-section,.married-section,.important-info {
					font-size: $base-font + 1px;
				}
				
				.plcc-form-section {
				    width: 100%;
				    padding: 38px 28px 7px 28px;
				}
			}
		}
		
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea  {
	    		color: $black;	
			}
		}
		
		.question-mark-icon {
		    margin-right: 15px;
		}
		
		.plcc-primary {
			.review-form-error-msg {
				font-size: $base-font;
			}
		}	
	} 
	
	@media screen and (max-width: 767px) {
		&.plcc-pre-approval-main {
			padding: 0 10px;
			.question-mark-icon {
				margin-right: 8px;
			}
			.custom-select {
			    font-size: 0;
			}
			.plcc-profile-summary {
				.plcc-profile-name,
				.plcc-profile-address,
				.plcc-city,
				.plcc-profile-email {
					line-height: 20px;
				}
				.plcc-edit-profile {
					line-height: 24px;
				}
			}
			
			.plcc-primary {
				.phone-number-msg,
				.child-support-section,
				.married-section,
				.important-info {
					padding: 0 17px;
				}
				.form-action {
					.form-row-button {
						&.save-button {
							button {
								font-size: $base-font;
							}
						}
					}
				}
				.plcc-form-row {
					.plcc-form-row-1 {
						margin-bottom: 20px;
					}
				}
				.form-row {
					&.month, &.day {
						margin-right: 5%;
					}
					&.month {
						width: 28%;
					}
					&.day {
					    width: 30.5%;
					}
					&.year {
						//width: 30.3%;
					}
					&.form-indent {
						padding: 0;
						label {
							line-height: 16.5px;
						}
					}
				}
				.plcc-ssn-label, .extra-label {
					color: $slightblack;
				}
			}
			
			.plcc-form-section {
			    padding: 25px 10px;
		        width: 100%;
		        .plcc-masked-number {
		        	font-size: $base-font;
		        }
			}
			
			.plcc-secondary {
			    padding: 0 22.5px;
			}
			.plcc-main-heading {
				padding: 0 22.5px;
				font-size: $base-font + 2px;
			    margin: 0 0 10px 0;
			}
		}
		.plcc-primary {
			.plcc-form-section, .plcc-form-row {
			}
			.phone-number-msg,.child-support-section, .married-section,.important-info {
				line-height: 18.5px;
			}
			.form-row {
				&.form-indent {
					label {
					    float: left;
    					width: 88%;
    					font-size: $base-font - 1px;		
					}
				}
			}
			.extra-label, .plcc-ssn-label {
				font-size: $base-font;
			}		
		}
		.plcc-left-nav-heading{
			font-size: $base-font;
		    line-height: 16px;
    		margin: 0;
		}
		.plcc-left-nav-info {
			font-size: $base-font;
			line-height: 18.5px;
		}
    	.plcc-secondary {
    		.see-benefits {
				font-size: $base-font - 2px;
			}
    	}
    }
    
    .plcc-primary {
	    .phone-number-msg, .contact-disclaimer {
			display: block;
		}
		.plcc-web-instance-page {
    		.phone-number-msg {
				display: block;	
			}
    	}
	}    
}

.address-main-heading {
	font-family: $HurmeBlack;
	font-size: $base-font + 12px;
	letter-spacing: 3px;
}

.default-indicator {
	@include WorkSansMedium($WorkSans);
	font-size: $base-font + 2px;
}

.paragraph {
	 @include WorkSansMedium($WorkSans);
	font-size: $base-font + 2px;
	
}

.address-list {
	li {
		.address-list-sec {
		    margin-bottom: 18px;
		}
	}
}

.address-list-sec {
	@include WorkSansMedium($WorkSans);
	font-size: $base-font + 2px;
	
	@media screen and (max-width: 767px) {
		width: 100%;
	}	
	.mini-address-title {
		font-family: $WorkSans;
		font-weight: 700;
	}
	a, .address-delete, .address-edit, .cancel {
		color: $black;
	}
	.delete-content {
	    .delete-msg {
	    	font-family: $Helvetica;
			font-size: $base-font + 4px;
	    }
	    .delete-controls {
	    	.cancel, .address-delete {
		    	font-family: $HelveticaBold;
				font-size: $base-font + 4px;
	    	}
	    }
	    
	}
}
.address-list-sec {
	.address-info {
		a {
			margin: 0 29px 0 0;
		}
		.address-links {
			a, button.address-delete {
				letter-spacing: .5px;
			}
		}
	}
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.address-form-block {
		.make-label-absolute {
			.form-row {
				label {
					font-weight: 500;
				
					&[for*="country"] {
						margin: -1px auto 0;
					}
				}
				
				&.form-row-button {
					.apply-button{
						font-family: $HurmeBlack;
						font-size: $base-font + 4px;
						padding: 12.5px 30px;
						letter-spacing: 3px;
					}
					
					.cancel-address {
				        background: none;
					    color: $black;
					    font-size: $base-font + 4px;
					    letter-spacing: 3px;
					}
				}
			}
		}
	}
	
	.form-validation-error-msg {
		&.error {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font;
		}
	}
	
	.address-create {
		@include WorkSansSemiBold($WorkSans);
		font-size: $base-font + 2px;
		
		&.no-click {
			color: $dim-gray;
			cursor: not-allowed;
		}
	}
	
	.make-label-absolute {
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea, .selected-option, input[type="tel"]  {
				color: $slightblack;
				@include WorkSansMedium($WorkSans);
				border-color: $black;
			}
		}
	}
	
	.profile-info {
		margin-bottom: 1.3%;
		
		h1 {
			text-transform: uppercase;
			font: {
				size: $base-font + 12px;
				family: $HurmeBlack;
				weight: normal;
			}
			display: inline-block;
			margin: 30px 0 0 6px;
			letter-spacing: 3px;
			
			@media screen and (max-width: 767px) {
				margin-top: 10px;
			}
		}
		
		h2 {
			font-size: $base-font + 1px;
			@include WorkSansRegular ($WorkSans);
			margin: 18px 0 16px 6px;
    		line-height: 21px;
    		letter-spacing: 0;
		}
		
		legend {
			font-size: $base-font + 12px;
			@include WorkSansMedium ($WorkSans);
			padding: 26px 0 12px 2px;
			margin: 0 0 33px 0;
		    display: inline-block;
		    width: 100%;
		}
		
		.inner-content {
			padding: 0 9.3% 5.4% 4.4%;
		}
		
		button {
		    letter-spacing: 3px;
		    font-size: $base-font + 4px;
		    padding: 12.5px 20px;
		}
		
		.make-label-absolute {
			.currentpassword{
				&.error-handle{
					.input-focus{
						text-align: left;
						letter-spacing: 0;
						.error{
							font-size: $base-font; 
						}
					}
				}
			}
		
			label {
				span {
					@include WorkSansRegular ($WorkSans);
					font-size: $base-font + 2px;
    				color: $dim-gray;		
				}
			}
			
			.form-row:not(.error-handle) {
				.field-wrapper {
					input[type="text"], input[type="password"] {
						padding: 17px 0 17px 20px;
					}
				}
			}
		}

		.personal-info, .change-password {
			background-color: $FB-lightest-gray;
		}
	}
	
	.setnewpassword {
		h1 {
     		font-size: $base-font + 12px;
     		@include WorkSansMedium($WorkSans);
     	}
     	
     	button {
 			background: $black;
		    border-color: $black;
		    @include WorkSansSemiBold($WorkSans);
			font-size: $base-font + 2px;
			height: 47px;
		}
		
		.resetpassword {
			a {
				@include WorkSansBold ($WorkSans);
			}
		}
	}
	
	//Check order status page 
	.check-status-order {
		input[type="text"] {
			border-radius: 0;	
		}
		.col-1 {
			p {
				 @include WorkSansMedium($WorkSans);
			}
		}
	}
}

// Account payment page.
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {	
	&.paymentinstruments {
		#secondary {
			nav {
				li {
					&.payment-methods {
						a {
							color: $black;
						}
					}
				}
			}
		} 
	}
	
	.paymentslist {
		.top-content {			
			h1 {
				font-size: $base-font + 12px;
				letter-spacing: 3px;
				text-transform: uppercase;
			}
			
			.default-msg {
				@include WorkSansMedium($WorkSans);
				line-height: 34px;
				font-size: $base-font + 2px;
			}
		}

		
		.payment-list {
			.cardinfo {
				.carddetails {
					.plcc-card-message {
						 @include WorkSansMedium($WorkSans);
						
						b {
	    					@include WorkSansBold ($WorkSans);
	    				}
	    				
						.rewards {
							color: $black;
						}	
					}
				}
			}
		}
		
		&.make-label-absolute {
			.makeDefault {
				label.input-focus {
					span {
						@include WorkSansMedium($WorkSans);
					}
				}
			}
		}
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			&.make-label-absolute .form-row {
				&.month,&.year {
					label {
						text-align: left;
						transform: translate(0,7px);
						width: 89%;
						max-width: 80px;
					}
				}
			}
		}

		.payment-form {
			background-color: $FB-lighter-gray;
			.form-row {
    			label {
    				span {
    					@include WorkSansMedium($WorkSans);
    				}
    			}
    		}
    		
			.right-cont {
				.billing-payment-description {
					.heading {
	    				@include WorkSansMedium($WorkSans);
					}
					
					.brand-cards {
						span {
	    					@include WorkSansMedium($WorkSans);
						}
					}
				}
			}
			
			.card-actions {
				.save-cancel {
					.cancel-button {
						font-size: $base-font + 4px;
						color: $black;
						letter-spacing: 3px;
						padding: {
							top: 14.5px;
    						bottom: 14.5px;
						}
					}
					
					.save-button {
						font-size: $base-font + 4px;
						letter-spacing: 3px;
						padding: {
							top: 12.5px;
    						bottom: 12.5px;
						}
					}
				}
			}
		}
		
		.payment-list {
			.showall {
				font-size: $base-font - 1px;
			    @include WorkSansSemiBold($WorkSans);
			}
			
			.cardinfo {
				.carddetails {
		    		@include WorkSansMedium($WorkSans);
		    		font-size: $base-font + 1px;
		    		
		    		.li.cardtype {
	    				@include WorkSansSemiBold($WorkSans);
		    		}
		    		
		    		.li.delete-card {
	 					@include WorkSansBold($WorkSans);
		    		}
		    		
		    		.li.make-default {
	    				@include WorkSansBold($WorkSans);
	    				color: $black;
	    				font-size: $base-font - 1px;
	    			}
	    			
	    			.li.delete-card {
				    	.button-text {
				    		@include WorkSansBold($WorkSans);
				    		color: $black;
				    	}
				    }
				}
			}
		}
	}
}

//Email preferences page for FB.
.legacy-sitegen .email-preferences {
	h1 {
		letter-spacing: 3px;
		text-transform: uppercase;
		&.otherbrands-divider {
			background-color: $FB-lighter-gray;
			letter-spacing: 0.5px;
    		font-size: 20px;
		}
	}
	
	.edit-click {
		@include WorkSansSemiBold($WorkSans);
		font-size: $base-font + 2px;
    	letter-spacing: 1px;
	}
	
	.email-thankyou {
		margin-bottom: 8px;
		
		h1 {
			&.offer {
				@include WorkSansBold($WorkSans);
				color: $black;
			}			
		}
		
		.code {
		    @include WorkSansSemiBold($WorkSans);
		    
		    .value {
		    	@include WorkSansSemiBold($WorkSans);
		    	color: $black;
		    }
		}
		
		.expirytime {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font;
		}
	}
	
	.user-info {
		span {
			margin: 10px 0;
			
			&.email-communication {
			    margin: 5px 0;
			}
		}
	}
	
	.email-preference-row {
		.col-2 {
			.email-description {
				font-size: $base-font + 1px;
				@include WorkSansMedium($WorkSans);
				letter-spacing: 0.5px;
				line-height: 19px;
			}
			
			.preference-options {
				.options-checkbox {
					font-size: $base-font + 1px;
					@include WorkSansMedium($WorkSans);
					margin: 32px 34px 0 0;
				}
				
				.emailsubscribe {
					font-size: $base-font + 4px;
					letter-spacing: 3px;
					padding: {
						top: 12.5px;
    					bottom: 12.5px;
					}
				}
			}
			
			.left-text {			    
			    h3 {
					font-family: $HurmeSemiBold;
					font-size: $base-font + 4px;
					letter-spacing: 0.5px;
					line-height: 28px;
			    }
			}
		}
	}
	
	.sisterbrands {
		.email-preference-row {
			padding: 12px 12px 26px;
			
			.col-2 {
				.email-description {
					font-size: $base-font;
					@include WorkSansMedium($WorkSans);
					letter-spacing: 0;
				}
			}
		}
	}
	.morelink {
		color: $black;
	}
}

.custom-slider-checkbox {
	.slider-label {
		@include WorkSansSemiBold($WorkSans);
	}
	
	input:checked + .slider {
		background-color: $black;
	}
}

// Delete card confirmation dialog
.ui-dialog {
	&.delete-payment-card {
		width: 760px !important;
	}
	
	.delete-creditcard-overlay {
		max-width: 615px;
		
		h1 {
			letter-spacing: 3px;
		}
		
		.confirm-delete-creditcard {
            font-size: $base-font + 4px;
    		letter-spacing: 3px;
        }

    	.cancle-delete-creditcard {
			min-width: 175px;
			font-size: $base-font + 4px;
    		letter-spacing: 3px;
			
			@media screen and (max-width: 767px) {
				min-width: 46.5%;
			}
		}
	}
}

.emailsubscription-guest {
	.subcription-content {
		@include WorkSansSemiBold($WorkSans);
	}
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.email-unsubscribe-guest {
		&.make-label-absolute {
			.form-row {
				@media screen and (min-width: 768px) {
					.field-wrapper {
						input[type="text"] {
							padding: 11px 0 13px 17px;
							border-top-right-radius: 0;
	    					border-bottom-right-radius: 0;
						}
					}
					
					.emailsubscribeguest-unsubscribe {
						border-top-left-radius: 0;
	    				border-bottom-left-radius: 0;
					}
				}	
			}
		}		
	}	
}

.subscribe-emailid {
	.form-row-button {
		button {
		    max-height: 52px;
		}
	}
	
	.email-box {
		@include WorkSansMedium($WorkSans);
		 height: 48px;
		 line-height: 48px;
	}
}

.unsubscribe-confirmation-email {
	.email-unauthenticatedunsubscribe-subhead {
		span {
			@include WorkSansMedium($WorkSans);
		}
	}
}

.email-frequency-modal {
	@include WorkSansMedium($WorkSans);
	
	button {
		min-width: 200px;
	}
}

.forgot-password {
	.make-label-absolute {
		.field-wrapper {
			input[type="text"] {
				-webkit-appearance: none;
			}
		}
	}
	
	.wrapper-forgot-password {
		h1 {
			@include WorkSansMedium($WorkSans);
		}
		
		p {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font + 2px;
			line-height: 28px;
			
			@media screen and (max-width: 767px) {
				br {
					display: none;
				}
			}
		}
		.passwordResetConfirmMsg{
			@include WorkSansMedium($WorkSans);
			@media screen and (max-width: 767px) {
				font-size:$base-font;
				line-height:22px;
			}

		}		
	}
	
	button {
		background: $black;
	    border-color: $black;
	    @include WorkSansSemiBold($WorkSans);
	    font-size: $base-font + 2px;
	}
	
	.close-button {
		@include WorkSansBold ($WorkSans);
		font-size: $base-font + 2px;
	}
	
	.make-label-absolute {
		.field-wrapper {
			input[type="text"] {
				font-size: $base-font + 2px;
				@include WorkSansMedium($WorkSans);	
				color: $slightblack;
			}
		}
		
		label {
			span {
				color: $dusty-gray;	
			}
		}
	}
}

//Catalog request
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.catalog-request{ 
		max-width: 930px;
		
		.address-form-block {
        	.make-label-absolute {
        		.form-row {
        			&.state {
            			label {
            				&.input-focus {
    							transform: translate(0, 4px);
            				}
            			}
            		}
        		}
        	}
        }
        
		.catalog-request-header {
			margin: 31px 0 19px 0;
			letter-spacing: 3px;
			text-transform: uppercase;
		}
		
		.catalog-request-callout {
			font-size: $base-font + 2px;
			margin-bottom: 17px;
		}
		
		.address-form-block {
			border: 0;
			padding: 39px 52px 44px 65px;
			width: 100%;
			background-color: $FB-lighter-gray;
		
			.address-section {
				div {
					&.catalog-prefernces {
						padding-left: 10px;
		
						div {
							&.form-row {
								label { 
									span {
										@include WorkSansRegular ($WorkSans);
										letter-spacing: .5px;
									    color: $dim-gray;
									    font-size: $base-font + 2px;
									}
		
									&.input-focus {
			    						transform: translate(5px, 11px);
									}
								}
							}
						}
					}
				}
			}
			
			.make-label-absolute { 
				.form-row {
					padding: 0 4.5% 0 0;
					
					&.form-row-button { 
						.apply-button {
							width: 69%;
							padding: 15px 10px;
						}
						
						.brown-button {
							padding: {
								top: 12.5px;
						    	bottom: 12.5px;
							}
						    font-size: $base-font + 4px;
						    letter-spacing: 3px;
						}
					}
					
					label {
						span {
							@include WorkSansRegular ($WorkSans);
							letter-spacing: .5px;
						    color: $dim-gray;
						    font-size: $base-font + 2px;
						}
					}
					
					input {
						@include WorkSansRegular ($WorkSans);
						padding: 22px 15px 10px;
					}
				}
			}
		}
		
		.catalog-request-step1, .catalog-request-step2 { 
			font-size: 14px;
			letter-spacing: 0.7px;
			
			span {
				@include WorkSansSemiBold($WorkSans);
				letter-spacing: 0.5px;
			}
		}
		
		.catalog-request-step2 { 
			line-height: 21px;
			width: 91%;
		}
		
		.make-label-absolute {
			.field-wrapper { 
				input {
					@include WorkSansMedium($WorkSans);
					padding: 18px 15px 14px 15px;
				}
				
				.selected-option{
					@include WorkSansMedium($WorkSans);
				}
				
				select{
					@include WorkSansMedium($WorkSans);
				}
			}
		}
	}
	
	.catalog-registered {
		#secondary { 
			.secondary-navigation {
				.navigation-links-row {
					li {
						&.catalog-preferences {
							a {
								color: $black;
							}
						}
					}
				}
				
				@media screen and (min-width: 768px) {
					margin-bottom: 37px;
				}
			}
		}
	}
}

// Address
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.internation-shipment-method {
		font-size: $base-font + 2px;
		font-family: $WorkSans;
		background: $light-black-two;
		color: $white;
		text-align: center;
		line-height: 18px;
    	padding: 6px 0 8px;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		
		@media screen and (max-width: 767px) {
		    text-align: left;
		    padding: 6px 9px 8px;
		    
		    .shipping-method {
		    	margin-right: 15px;
		    }
		}
	}
	
	&.addresses {
		#secondary { 
			.secondary-navigation {
				@media screen and (min-width: 768px) {
					margin-bottom: 37px;
				}
			}
		}
		
		.address-conatiner {
			max-width: 958px;
		
			.address-links {
				font-size: $base-font + 2px;
				font-weight: 700;

				button{
					font-size: $base-font + 2px;
					font-weight: 700;	
				}
			}
		}
		
		.addressinfo {
			margin-top: 30px;
			
			@media screen and (max-width: 767px) {
				margin-top: 15px;
			}
		}
		
		.default-indicator {
			margin: 13px 0 0 22px;
		    padding: 0 0 0 17px;
		    letter-spacing: .5px;
		}
		
		.address-create {
			margin-top: 12px;
			
			&:hover {
				color: $black;
			}
		}
		
		.address-main-heading {
			margin-bottom: 22px;
			text-transform: uppercase;
		}
		
		.address-list { 
			li {
				width: 43.4%;
				.address-list-sec {
					&.default, &:hover {
						background-color: $FB-lighter-gray;
					}
				}
			}
		
			.address-form-block {
				padding: 29px 61px 26px 62px;
			}
		}
		
		.address-form-block { 
			.make-label-absolute { 
				.form-row {
					margin: 0 0 13px 0;
					
					&.form-row-button {
						margin-bottom: 0;
					}
					
					.field-wrapper {
						input[type="text"], .field-wrapper .custom-select .selected-option {
							padding: 16px 0 16px 20px;
						}
					}
					
					.field-wrapper .custom-select .selected-option {
						line-height: normal;
					}
					
					.selected {
						font-size: $base-font + 2px;
					}
					
					label {
						span {
							font-size: $base-font + 2px;
							letter-spacing: .5px;
							color: $dim-gray;
						}
					}
				}
				
				label {
					&.input-focus { 
						span {
							font-size: $base-font - 1px;
							letter-spacing: .4px;
						}
					}
				}
				
				.form-row {
					&.makeDefault {
						label {
							transform: none;
							
							span {
								font-size: 12pt;
							    font-weight: 500;
							    color: $black;
							}
							
							&.input-focus { 
								span {
									font-size: 12pt;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
		.catalog-request { 
			.catalog-request-step1, .catalog-request-step2{
				@include WorkSansMedium($WorkSans);
				font-weight: normal;
			}
			
			.catalog-prefernces {
				span {
					@include WorkSansMedium($WorkSans);
					font-weight: normal;
				}
			}
		}
		
		&.profile {
			.profile-info {
				.inner-content {
					padding: 0 3.4% 5% 3.4%;
				}
				
				.custom-select {
					.form-row {
						width: 100%;
					}
				}
				
				.form-row-button {
					padding: 0;
				}
			}
		}
		
		&.addresses{ 
			.address-list { 
				.address-form-block {
					padding: 26px 10px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account { 
		.catalog-request {
			max-width: 730px;
			
			.address-form-block {
				padding: 37px 30px 45px 50px;
		
				.make-label-absolute { 
					.form-row{
						padding: 0px 5.2% 0 0;
					}
				}
			}
		}
		
		&.addresses { 
			.address-conatiner {
				margin: 0 20px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
		&.profile {
			.profile-info {
				.form-row {
					width: 100%;
				}
				
				legend {
					padding: 20px 0 20px 0;
				}
			}
		}
		
		&.addresses {
			.address-list { 
				li {
					width: 100%;
				}
			}
			
			#secondary { 
				.secondary-navigation {
					margin-bottom: 20px;
				}
			}
			
			.address-main-heading {
				margin-bottom: 15px;
			}
		}
		
		.catalog-request{ 
			.catalog-request-header {
				margin: 10px 0 10px 0;
			}
			
			.catalog-request-callout {
				font-size: 13px;
				line-height: 24px;
				margin-bottom: 9px
			}
			
			.catalog-request-step1, .catalog-request-step2{
				font-size: 13px;
				line-height: 20px;
			}
			
			.address-form-block {
				padding: 13px 11px 25px;
				
				div {
					&.catalog-prefernces {
						padding: 21px 0 13px 0;
						
						div {
							&.form-row {
								margin: 0 0 22px 0;
							}
						}
					}
				}
				
				.make-label-absolute {
					label { 
						span {
							font-family: $HelveticaMedium;
						}
					}
					
					.field-wrapper {
						input {
							font-family: $HelveticaMedium;
							padding: 24px 15px 10px;
						}
					
						select {
							font-family: $HelveticaMedium;
						}
					}
					
					.form-row {
						padding: 0;
					
						&.form-row-button {
							button {
								&.apply-button {
									padding: 14px 10px;
									font-size: 15px;
									width: 100%;
								}
							}
						}
					}
					
					.catalog-prefernces { 
						span {
							 @include WorkSansMedium($WorkSans);
						}
					}
				}
			}
		}
	}
	
	// Delete card confirmation dialog
	.ui-dialog {
		&.delete-payment-card {
			width: 638px !important;
		}
		
		.delete-creditcard-overlay {
			max-width: 500px;
		}
	}
}

@media screen and (max-width: 767px) {
	.ui-dialog {
		.delete-creditcard-overlay {
			max-width: 100%;
		}
	}
	
	.legacy-sitegen .email-preferences {
		.user-info {
			span {
				font-size: $base-font + 1px; 
			}
		}
		
		.email-preference-row {
			.col-2 {
		 		font-size: $base-font;
		 		
		 		.preference-options {
		 			.options-checkbox {
		 				margin: 20px 5px 0 0;
		 				
		 				.input-checkbox {
		 					margin: 0 5px 0 0;
		 				}
		 			}
		 		}
		 	}
		}
		
		.sisterbrands {
			.email-preference-row {
				padding: 10px 0;
			}
		}
		
	}
}

@media screen and (max-width: 480px){
	.legacy-sitegen .email-preferences {
		.email-preference-row {
			.col-2{
				.left-text{
					width: 45%;
				}
			}
		}
	}
}
.create-login {    
    &.login-order-history {    	
    	.login-section, .check-order {
    		background: $FB-lighter-gray !important;
    	}
    }
}

.plcc-main .plcc-primary .form-action .form-row-button.cancel-button a {
	padding: 19px 21px;
	letter-spacing: 3px;
	font-family: $HurmeBlack;
	font-weight: 900;
}