#main {
    .product-tile {
        .product-name {
            font-family: $HurmeSemiBold;
            font-size:$base-font;
            box-sizing:border-box;
        }
    }
}
.home-content-container.div-trending-section .you-may-like .product-name-image-container .product-image.product-brylane img{
	height:317px;
	@media screen and (min-width:1025px) and (max-width:1200px) {
		height: 26vw;
	}  
	@media screen and (width:1024px){
		height: 23vw;
	}  
}